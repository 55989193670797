import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import { firebaseApp } from "../firebase/client"
import { ROUTES } from "../navigation/routes"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { mixpanelReset } from "../analytics/mixpanel"
import { SiteMetaHelmet } from "../components/SiteMeta/SiteMeta"

const SignOutPage: React.FC = () => {
  const mixpanel = useMixpanel()
  useEffect(() => {
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        mixpanelReset(mixpanel)
        setTimeout(() => {
          navigate(ROUTES.home.path, {
            replace: true,
          })
        }, 500)
      })
  }, [])

  return (
    <SiteWrapper darkTheme>
      <SiteLayout>
        <PageLocation pageName={ROUTES.signOut.name}>
          <SiteMetaHelmet>
            <title>Sign Out</title>
          </SiteMetaHelmet>
          <div>You have been signed out.</div>
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default SignOutPage
